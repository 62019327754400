import { type Config } from 'tailwindcss';
import defaultTheme from 'tailwindcss/defaultTheme.js';

export const extendedTheme = {
	colors: {
		border: 'rgb(var(--border))',
		input: {
			DEFAULT: 'rgb(var(--input))',
			invalid: 'rgb(var(--input-invalid))',
		},
		ring: {
			DEFAULT: 'rgb(var(--ring))',
			invalid: 'rgb(var(--foreground-destructive))',
		},
		background: 'rgb(var(--background))',
		foreground: {
			DEFAULT: 'rgb(var(--foreground))',
			destructive: 'rgb(var(--foreground-destructive))',
		},
		primary: {
			DEFAULT: 'rgb(var(--primary))',
			hover: 'rgb(var(--primary-hover))',
			active: 'rgb(var(--primary-active))',
			foreground: 'rgb(var(--primary-foreground))',
			light: {
				DEFAULT: 'rgb(var(--primary-light))',
				hover: 'rgb(var(--primary-light-hover))',
				active: 'rgb(var(--primary-light-active))',
			},
			dark: {
				DEFAULT: 'rgb(var(--primary-dark))',
				hover: 'rgb(var(--primary-dark-hover))',
				active: 'rgb(var(--primary-dark-active))',
			},
			darker: 'rgb(var(--primary-darker))',
		},
		secondary: {
			DEFAULT: 'rgb(var(--secondary))',
			hover: 'rgb(var(--secondary-hover))',
			active: 'rgb(var(--secondary-active))',
			foreground: 'rgb(var(--secondary-foreground))',

			light: {
				DEFAULT: 'rgb(var(--secondary-light))',
				hover: 'rgb(var(--secondary-light-hover))',
				active: 'rgb(var(--secondary-light-active))',
			},

			dark: {
				DEFAULT: 'rgb(var(--secondary-dark))',
				hover: 'rgb(var(--secondary-dark-hover))',
				active: 'rgb(var(--secondary-dark-active))',
			},
		},
		destructive: {
			DEFAULT: 'rgb(var(--destructive))',
			foreground: 'rgb(var(--destructive-foreground))',
		},
		muted: {
			DEFAULT: 'rgb(var(--muted))',
			foreground: 'rgb(var(--muted-foreground))',
		},
		accent: {
			DEFAULT: 'rgb(var(--accent))',
			hover: 'rgb(var(--accent-hover))',
			active: 'rgb(var(--accent-active))',
			foreground: 'rgb(var(--accent-foreground))',
		},
		greyscale: {
			DEFAULT: 'rgb(var(--greyscale-normal))',
			hover: 'rgb(var(--greyscale-hover))',
			active: 'rgb(var(--greyscale-active))',
			light: {
				DEFAULT: 'rgb(var(--greyscale-light))',
				hover: 'rgb(var(--greyscale-light-hover))',
				active: 'rgb(var(--greyscale-light-active))',
			},
			dark: {
				DEFAULT: 'rgb(var(--greyscale-dark))',
				hover: 'rgb(var(--greyscale-dark-hover))',
				active: 'rgb(var(--greyscale-dark-active))',
			},
		},
		neutral: {
			DEFAULT: 'rgb(var(--neutral-normal))',
			hover: 'rgb(var(--neutral-hover))',
			active: 'rgb(var(--neutral-active))',
			light: {
				DEFAULT: 'rgb(var(--neutral-light))',
				hover: 'rgb(var(--neutral-light-hover))',
				active: 'rgb(var(--neutral-light-active))',
			},
			dark: {
				DEFAULT: 'rgb(var(--neutral-dark))',
				hover: 'rgb(var(--neutral-dark-hover))',
				active: 'rgb(var(--neutral-dark-active))',
			},
			darker: 'rgb(var(--neutral-darker))',
		},
		popover: {
			DEFAULT: 'rgb(var(--popover))',
			foreground: 'rgb(var(--popover-foreground))',
		},
		card: {
			DEFAULT: 'rgb(var(--card))',
			foreground: 'rgb(var(--card-foreground))',
		},
	},
	borderColor: {
		DEFAULT: 'rgb(var(--border))',
	},
	borderRadius: {
		lg: 'var(--radius)',
		md: 'calc(var(--radius) - 2px)',
		sm: 'calc(var(--radius) - 4px)',
	},
	fontFamily: {
		sans: ['var(--font-sans)', ...defaultTheme.fontFamily.sans],
		display: ['var(--font-display)', ...defaultTheme.fontFamily.sans],
	},
	fontSize: {
		xs: ['0.75rem', { lineHeight: '1rem' }],
		sm: ['0.875rem', { lineHeight: '1.5rem' }],
		base: ['1rem', { lineHeight: '1.75rem' }],
		lg: ['1.125rem', { lineHeight: '1.75rem' }],
		xl: ['1.25rem', { lineHeight: '2rem' }],
		'2xl': ['1.5rem', { lineHeight: '2.25rem' }],
		'3xl': ['1.75rem', { lineHeight: '2.25rem' }],
		'4xl': ['2rem', { lineHeight: '2.5rem' }],
		'5xl': ['2.5rem', { lineHeight: '3rem' }],
		'6xl': ['3rem', { lineHeight: '3.5rem' }],
		'7xl': ['4rem', { lineHeight: '4.5rem' }],
		/** 12px size / 16px high / bold */
		button: ['0.75rem', { lineHeight: '1rem', fontWeight: '700' }],
	},
} satisfies Config['theme'];
